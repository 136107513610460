import type { HTMLAttributes } from 'react'
import { memo } from 'react'

import type { ImageOptions } from './useImage'
import type { SourceOptions } from './useSources'
import { useSources } from './useSources'

interface Props extends HTMLAttributes<HTMLPictureElement> {
  sources: SourceOptions[]
  img: ImageOptions
  preload?: boolean
}

function Picture({
  sources: sourceOptions,
  img: imgProps,
  ...otherProps
}: Props) {
  const sources = useSources(sourceOptions)

  return (
    <picture {...otherProps}>
      {sources.map(({ srcSet, media, height }, index) => {
        return (
          <source key={index} srcSet={srcSet} media={media} height={height} />
        )
      })}
      <img
        {...imgProps}
        data-src={imgProps.src}
        alt={imgProps.alt}
        data-id={imgProps.dataId}
        {...otherProps}
      />
    </picture>
  )
}

export default memo(Picture)
