import axios from 'axios'
import { useState } from 'react'
import { useAccountContext } from 'src/components/account/context'
import { useWishlistContext } from 'src/components/contexts/wishlist-context'

function useWishlist() {
  const { memberAuthToken } = useAccountContext()
  const [isEmptyWishlist, setIsEmptyWishlist] = useState<boolean>(false)
  const { wishlistId } = useWishlistContext()

  const getWishlist = async () => {
    if (!memberAuthToken) {
      return null
    }

    try {
      const { data } = await axios
        .post('/api/wishlist/getWishlist', {
          memberAuthToken,
        })
        .then((res) => {
          if (res?.data?.products.length === 0) {
            setIsEmptyWishlist(true)
          }

          return res
        })

      return { data }
    } catch (error) {
      throw new Error(`[API Error | Get Wishlist]', ${error}`)
    }
  }

  const addProductToWishlist = async (productId: string) => {
    if (!memberAuthToken) {
      return null
    }

    if (wishlistId === '') {
      throw new Error('[API Error | Wishlist ID]')
    }

    try {
      const { data } = await axios.post('/api/wishlist/addProductToWishlist', {
        memberAuthToken,
        wishlistId,
        productId,
      })

      return data
    } catch (error) {
      throw new Error(`[API Error | Add Product To Wishlist]', ${error}`)
    }
  }

  const removeProductFromWishlist = async (productId: string) => {
    if (!memberAuthToken) {
      return null
    }

    if (wishlistId === '') {
      throw new Error('[API Error | Wishlist ID]')
    }

    try {
      const { data } = await axios.post(
        '/api/wishlist/removeProductFromWishlist',
        {
          memberAuthToken,
          wishlistId,
          productId,
        }
      )

      return data
    } catch (error) {
      throw new Error(`[API Error | Remove Product From Wishlist]', ${error}`)
    }
  }

  return {
    getWishlist,
    addProductToWishlist,
    removeProductFromWishlist,
    isEmptyWishlist,
    memberAuthToken,
  }
}

export default useWishlist
