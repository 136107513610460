import { B2B } from 'src/store-config/B2B-Config'
import { B2C } from 'src/store-config/B2C-Config'

export const redirectToAccountOrLogin = (
  isB2B: boolean,
  isLoggedIn = false
) => {
  if (isB2B) {
    return B2B.accountUrl
  }

  if (isLoggedIn) {
    return B2C.accountUrl
  }

  return B2C.loginUrl
}
