import { getCookie } from 'src/utils/getCookie'

import CashbackBalance from '../../CashbackBalance/CashbackBalance'
import LoggedInContent from './LoggedInContent'
import LoggedOutContent from './LoggedOutContent'

type LoginDropdownProps = {
  className?: string
}

const LoginDropdown = ({ className = '' }: LoginDropdownProps) => {
  const userDataCookie = getCookie('memberAuthToken')

  return (
    <div className={className}>
      <CashbackBalance userDataCookie={userDataCookie} />

      <div className="px-6 py-4">
        {userDataCookie ? <LoggedInContent /> : <LoggedOutContent />}
      </div>
    </div>
  )
}

export default LoginDropdown
