import { useEffect, useState } from 'react'
import AccountMenu from 'src/components/account/AccountMenu'
import { getCookie } from 'src/utils/getCookie'

import CashbackBalance from '../../CashbackBalance'
import { TitleSubmenu } from '../HeaderMobile/components/TitleSubmenu'
import LogOut from '../LoginDropdown/LogOut'

type MyAccountSubMenuProps = {
  isMyAccountSubMenuOpen: boolean
  handleMyAccountSubMenu: (state: boolean) => void
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MyAccountSubMenu = ({
  isMyAccountSubMenuOpen,
  setMenuIsOpen,
  handleMyAccountSubMenu,
}: MyAccountSubMenuProps) => {
  const userDataCookie = getCookie('memberAuthToken')

  const [vh, setVh] = useState(window.innerHeight * 0.01)

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight * 0.01)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div
      className={`nav-content flex flex-col fixed justify-start h-[100vh] top-0 left-0 w-full z-[1] bg-white ${
        isMyAccountSubMenuOpen ? `entrance-animation ` : `exit-animation`
      }`}
    >
      <div
        className="relative"
        style={{
          height: `calc(${vh}px * 100)`,
        }}
      >
        <div>
          <TitleSubmenu
            setMenuIsOpen={setMenuIsOpen}
            actionClose={() => handleMyAccountSubMenu(false)}
            title="Minha Conta"
          />
          <div className="px-4">
            <CashbackBalance
              borderRadius="rounded-lg"
              userDataCookie={userDataCookie}
            />
          </div>
        </div>

        <div className="flex px-4  mt-6">
          <AccountMenu onCloseModal={setMenuIsOpen} />
        </div>

        <div className="h-[88px] flex items-center bg-neutral02 absolute bottom-0 left-0 z-[9999] w-full">
          <LogOut className=" ml-4 text-base" />
        </div>
      </div>
    </div>
  )
}

export default MyAccountSubMenu
