import axios from 'axios'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import type { FooterSection, StripeSection } from 'src/components/global/types'

type GlobalProviderProps = {
  children: React.ReactNode
}

export type GlobalContextProps = {
  stripeSection?: StripeSection
  globalSectionsCMS: Array<SectionCMS<SectionsCMS>>
}

export const GlobalContext = createContext<GlobalContextProps>(
  {} as GlobalContextProps
)

type SectionCMS<T> = {
  data: T
  id: string
  name: string
}

type SectionsCMS = StripeSection | FooterSection

export function findSectionCMSByName(
  sections: Array<SectionCMS<SectionsCMS>>,
  sectionName: string
) {
  return sections.find((section) => section.name === sectionName)
}

type CMSPage = {
  name: string
  sections: Array<SectionCMS<SectionsCMS>>
}

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const [stripeSection, setStripeSection] = useState<StripeSection | undefined>(
    undefined
  )

  const [globalSectionsCMS, setGlobalSectionsCMS] = useState<
    Array<SectionCMS<SectionsCMS>>
  >([])

  useEffect(() => {
    async function exec() {
      const cmsData = await axios.get<CMSPage>('/api/cms/getPageData', {
        params: {
          contentType: 'global',
        },
      })

      const stripe = findSectionCMSByName(
        cmsData.data.sections,
        'Stripe - Header [Global]'
      )

      setStripeSection(stripe?.data as StripeSection)
      setGlobalSectionsCMS(cmsData?.data.sections)
    }

    exec()
  }, [])

  const productContextValue = useMemo(
    () => ({
      stripeSection,
      globalSectionsCMS,
    }),
    [stripeSection, globalSectionsCMS]
  )

  return (
    <GlobalContext.Provider value={productContextValue}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => useContext(GlobalContext)
