import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type { Product } from 'src/components/restructure/product/types/product'

import { makeProductShowcase } from './makeProductShowcase'
import type { AddToCartEvent } from './types/addToCartEvent'
import handleUsablePageName from './common/handleUsablePageName'

export const makeAddToCartBuyTogetherEvent = (
  items: Array<{ product: Product; productItem: ProductItem }>
): AddToCartEvent => {
  const dimension24 = handleUsablePageName(window.location.pathname)

  return {
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'BRL',
      add: {
        products: items.map((item) =>
          makeProductShowcase(
            item.product,
            item.productItem,
            dimension24,
            'linx'
          )
        ),
      },
    },
  }
}
