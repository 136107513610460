import { Link } from 'gatsby'
import { useState } from 'react'
import { Statsig } from 'statsig-react'
import Icon from 'src/components/ui/Icon'
import { useMenu } from 'src/graphql/queries/menu'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { useMobile } from 'src/hooks/useMobile'

import DropdownMenu from './DropdownMenu'
import { menuDataFallback } from './data/menuDataFallback'
import DropdownMenuGender from './DropdownMenuGender'

function Menu() {
  const { isMobile } = useMobile()
  const isB2B = checkEnviromentIsB2B()
  const [inHovering, setInHovering] = useState(false)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)
  const menuData = useMenu()
  const [sports, ...departments] =
    menuData.allCmsHome?.nodes?.[0]?.sections?.[0]?.data?.departments ??
    menuDataFallback.departments

  const handleMouseEnterWithDelay = (value: boolean) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    const id = setTimeout(() => {
      setInHovering(value)
    }, 300)

    setTimeoutId(id)
  }

  const handleMouseLeave = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    setInHovering(false)
  }

  return (
    <div
      className="relative w-full bg-white hidden restructure-small-desktop:flex flex-row basis-full max-h-[48px]"
      data-testid="menu-desktop"
    >
      <nav
        className={`gap-[70px] justify-between w-full  flex flex-row basis-full max-h-[48px] dropdown-container ${
          inHovering ? 'in--hovering' : ''
        } max-w-[1366px] mx-auto `}
      >
        <ul
          className="flex flex-1 flex-row justify-between items-center"
          onMouseLeave={handleMouseLeave}
        >
          <li
            onMouseEnter={() => {
              handleMouseEnterWithDelay(true)
            }}
            className="group h-full  text-center xl:pl-[6px] justify-start flex items-center pr-6 "
            data-testid="item-menu"
          >
            {sports.url && sports.url !== '/#' ? (
              <Link
                to={sports.url ?? ''}
                className="desktop-body-regular-text3 text-restructure-action text-center flex gap-2 items-center whitespace-nowrap border-b-2 border-solid border-transparent group-hover:border-restructure-background-action-1 group-hover:text-restructure-action"
                aria-label={sports.title}
                title={sports.title}
              >
                <Icon
                  name="Sports_basketball"
                  width={14}
                  height={14}
                  className="text-restructure-action"
                />
                {sports.title}
              </Link>
            ) : (
              <span
                className="pointer-events-none cursor-not-allowed desktop-body-regular-text3 text-restructure-action text-center flex gap-2 items-center whitespace-nowrap border-b-2 border-solid border-transparent group-hover:border-restructure-background-action-1 group-hover:text-restructure-action"
                aria-label={sports.title}
                title={sports.title}
                role="button"
                tabIndex={0}
              >
                <Icon
                  name="Sports_basketball"
                  width={14}
                  height={14}
                  className="text-restructure-action"
                />
                {sports.title}
              </span>
            )}

            {Boolean(sports.categories?.length) && inHovering && (
              <DropdownMenu
                {...sports}
                variant="second"
                setInHovering={setInHovering}
              />
            )}
          </li>
          {departments.map((department) => {
            const hasChildrenCategories = Boolean(department.categories?.length)

            return (
              <li
                key={department.title}
                className="flex-1 min-w-min h-full flex items-center justify-center text-center transition-all duration-75 group px-2 restructure-small-desktop:px-3"
                onMouseEnter={() => {
                  handleMouseEnterWithDelay(hasChildrenCategories)
                }}
                data-testid="item-menu"
              >
                <Link
                  to={department.url ?? ''}
                  className={`border-b-2 border-solid border-transparent ${
                    department.title === 'Outlet' ||
                    department.title === 'Liquidação'
                      ? 'text-restructure-error group-hover:border-restructure-background-error2'
                      : 'text-restructure-primary border-b-2 border-solid border-transparent group-hover:border-restructure-background-primary-inverted'
                  } desktop-body-regular-text3 text-center min-w-max `}
                  aria-label={department.title}
                  title={department.title}
                >
                  {department.title}
                </Link>
                {hasChildrenCategories && inHovering && (
                  <DropdownMenuGender
                    {...department}
                    urlDepartment={department.url}
                    setInHovering={setInHovering}
                  />
                )}
              </li>
            )
          })}
        </ul>
        <ul className="flex flex-row gap-6 items-center">
          {isB2B && (
            <>
              <Link
                to="/cartao-presente/p"
                className="desktop-caption-regular text-restructure-secondary hover:text-restructure-action"
                aria-label="Cartão Presente"
                title="Cartão Presente"
                data-testid="item-institutional"
              >
                Cartão Presente
              </Link>

              <Link
                to="/atendimento"
                className="desktop-caption-regular text-restructure-secondary hover:text-restructure-action"
                aria-label="Atendimento"
                title="Atendimento"
                data-testid="item-institutional"
              >
                Atendimento
              </Link>

              <Link
                to="https://secure.decathlonpro.com.br/cadastro"
                className="desktop-caption-regular text-restructure-secondary hover:text-restructure-action"
                aria-label="Cadastre seu CNPJ"
                title="Cadastre seu CNPJ"
                data-testid="item-institutional"
              >
                Cadastre seu CNPJ
              </Link>
            </>
          )}

          {!isB2B && (
            <>
              <a
                href="https://www.decathlonpro.com.br?utm_source=Site_B2C&utm_medium=header"
                className="desktop-caption-regular text-restructure-secondary hover:text-restructure-action"
                aria-label="Decathlon Empresas"
                title="Decathlon Empresas"
                data-testid="item-institutional"
              >
                Decathlon Empresas
              </a>

              <Link
                to="/atendimento"
                className="desktop-caption-regular text-restructure-secondary hover:text-restructure-action"
                aria-label="Atendimento"
                title="Atendimento"
                data-testid="item-institutional"
                onClick={() => {
                  Statsig.logEvent('direcionar_atendimento', null, {
                    device: isMobile ? 'mobile' : 'desktop',
                  })
                }}
              >
                Atendimento
              </Link>
              <Link
                to="/clube"
                className="desktop-caption-regular text-restructure-secondary hover:text-restructure-action"
                aria-label="Clube Decathlon"
                title="Clube Decathlon"
                data-testid="item-institutional"
              >
                Clube Decathlon
              </Link>
            </>
          )}
        </ul>
      </nav>

      {inHovering && (
        <div
          className={`fixed top-[175px] left-0 w-full h-full bg-black transition-opacity duration-100 ${
            inHovering ? 'opacity-50' : 'opacity-0'
          }`}
        />
      )}
    </div>
  )
}

export default Menu
