import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { B2B } from 'src/store-config/B2B-Config'
import { B2C } from 'src/store-config/B2C-Config'
import { Statsig } from 'statsig-react'
import { useMobile } from 'src/hooks/useMobile'
import { navigate } from 'gatsby'

import { useCheckout } from '../checkout/useCheckout'
import { useModal } from '../ui/modal/Provider'

export const useCheckoutButton = () => {
  const { onModalClose } = useModal()
  const { orderForm, isLoading } = useCheckout()
  const orderFormId = orderForm?.orderFormId

  const isB2B = checkEnviromentIsB2B()
  const { isMobile } = useMobile()
  const disabledToProceedToCheckout = isLoading || !orderFormId || !orderForm

  const onClick = () => {
    if (disabledToProceedToCheckout) {
      return
    }

    if (disabledToProceedToCheckout) {
      return
    }

    Statsig.logEvent('direcionar_checkout', null, {
      device: isMobile ? 'mobile' : 'desktop',
    })

    const B2BCheckout = `${B2B.checkoutUrl}?orderFormId=${orderFormId}#/cart`
    const B2CCheckout = B2C.checkoutUrl

    onModalClose()
    navigate(isB2B ? B2BCheckout : B2CCheckout)
  }

  return {
    onClick,
    disabled: disabledToProceedToCheckout,
    'data-testid': 'checkout-button',
  }
}
