import { Link } from 'gatsby'
import type { SetStateAction } from 'react'
import { ArrowExternalLink } from 'src/components/Icons/ArrowExternalLink'

const BASE_ROUTE = '/account'

const routes = [
  {
    label: 'Home',
    url: BASE_ROUTE,
  },
  {
    label: 'Minhas compras',
    url: `${BASE_ROUTE}/orders`,
  },
  {
    label: 'Meus favoritos',
    url: `${BASE_ROUTE}/wishlist`,
  },
  {
    label: 'Trocas e devoluções',
    url: `${BASE_ROUTE}/exchanges`,
  },
  {
    label: 'Gerenciar perfil',
    url: `${BASE_ROUTE}/profile`,
  },
  {
    label: 'Preciso de ajuda',
    url: `/atendimento`,
  },
  {
    label: 'Desconectar',
    url: `${BASE_ROUTE}/logout`,
  },
]

interface Props {
  onCloseModal?: (value: SetStateAction<boolean>) => void
}

const AccountMenu = ({ onCloseModal }: Props) => {
  const closeModal = onCloseModal ? () => onCloseModal(false) : undefined

  return (
    <aside className="flex flex-col justify-between flex-1 md:flex-none">
      <ul className=" md:[&>*:nth-last-child(2)]:mt-8 text-lg md:text-sm md:mb-8">
        {routes.map(({ label, url }, i) => (
          <li key={i} className="py-3 md:py-1 last:hidden md:last:block">
            <Link
              to={url}
              onClick={closeModal}
              className="mobile-heading-title4 text-[18px] aria-[current=page]:font-bold flex items-center gap-1 "
            >
              {label}
              {!url.includes(BASE_ROUTE) && <ArrowExternalLink />}
            </Link>
          </li>
        ))}
      </ul>
    </aside>
  )
}

export default AccountMenu
