import { Link } from 'gatsby'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'

const otherLinks = [
  {
    label: 'Favoritos',
    href: '/account',
    target: '_top',
  },
  {
    label: 'Nossos serviços',
    href: '/servicos',
    target: '_top',
  },
  {
    label: 'Decathlon Empresas',
    href: 'https://www.decathlonpro.com.br?utm_source=Site_B2C&utm_medium=header',
    target: '_top',
  },
  {
    label: 'Atendimento',
    href: '/atendimento',
    target: '_top',
  },
  {
    label: 'Clube Decathlon',
    href: '/clube',
    target: '_top',
  },
  {
    label: 'Trocas e Devoluções',
    href: '/servicos/trocas-e-devolucoes',
    target: '_top',
  },
]

interface Props {
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function FooterContent({ setMenuIsOpen }: Props) {
  const isB2B = checkEnviromentIsB2B()

  return (
    <footer className="flex flex-col bg-restructure-background-secondary h-full w-full">
      <nav className="pt-6 pb-8 px-4 flex flex-col">
        <h4 className="mobile-overline-medium desktop-overline-medium text-restructure-secondary mb-[18px] restructure-tablet:mb-4">
          ACESSO RÁPIDO
        </h4>
        <div className="flex flex-col mr-auto">
          {otherLinks.map((link, index) => {
            if (isB2B && link.label === 'Decathlon Empresas') return

            return (
              <div
                key={`${link.label}-${index}`}
                className="block max-w-fit mb-4"
              >
                {link.href.includes('https') ? (
                  <a
                    className="block mobile-body-regular-text2 desktop-body-regular-text3 text-restructure-primary "
                    href={link.href ?? ''}
                    aria-label={link.label ?? ''}
                    rel="noreferrer"
                    target={link.target}
                    onClick={() => setMenuIsOpen(false)}
                  >
                    {link.label}
                  </a>
                ) : (
                  <Link
                    className="block mobile-body-regular-text2 desktop-body-regular-text3 text-restructure-primary "
                    to={link.href ?? ''}
                    aria-label={link.label ?? ''}
                    rel="noreferrer"
                    target={link.target}
                    onClick={() => setMenuIsOpen(false)}
                  >
                    {link.label}
                  </Link>
                )}
              </div>
            )
          })}
        </div>
      </nav>
    </footer>
  )
}

export default FooterContent
