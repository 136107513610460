import { useLocation } from '@reach/router'
import { useMobile } from 'src/hooks/useMobile'
import { redirectToAccountOrLogin } from 'src/utils/redirectToAccountUrl'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

const LoggedOutContent = () => {
  const { isMobile } = useMobile()
  const location = useLocation()
  const isB2B = checkEnviromentIsB2B()

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    const trackEvent = makeEventTrack({
      eventPage: location.pathname === '/' ? 'home' : location.pathname,
      eventAction: e.currentTarget.ariaLabel ?? '',
    })

    sendEvent(trackEvent)
  }

  return (
    <div className="flex gap-6 flex-col font-normal font-inter">
      <p className="text-sm">
        Faça login para consultar o seu cashback e acompanhar seu pedido :)
      </p>

      <a
        href={redirectToAccountOrLogin(isB2B)}
        aria-label={`Fazer login${isMobile ? ' - mobile' : ''}`}
        className="w-full min-w-[182px] flex items-center justify-center h-8 bg-black text-white font-semibold font-inter text-sm rounded-round"
        onClick={(e) => handleClick(e)}
      >
        Fazer login
      </a>
    </div>
  )
}

export default LoggedOutContent
