import axios from 'axios'

import { OrderForm } from '../../entity/orderForm'
import { storage } from '../utils/storage'

const { getItem, setItem } = storage()

const isBrowser = typeof window !== `undefined`

export const getOrderForm = async (
  paramOrderFormId?: string
): Promise<OrderForm> => {
  if (!isBrowser) {
    return {
      orderFormId: '',
      items: [],
      messages: [],
      totalizers: {},
      clientProfileData: null,
      shippingData: {},
      loggedIn: false,
      clientPreferencesData: {},
      paymentData: {},
      marketingData: {},
    }
  }

  const storageOrderFormId = getItem('orderFormId')

  if (paramOrderFormId) {
    const newOrderForm = await requestOrderForm(paramOrderFormId)

    if (newOrderForm) {
      setItem('orderFormId', newOrderForm.orderFormId)

      return new OrderForm(newOrderForm)
    }
  }

  if (storageOrderFormId) {
    const newOrderForm = await requestOrderForm(storageOrderFormId)

    return new OrderForm(newOrderForm)
  }

  const newOrderForm = await createNewOrderForm()

  setItem('orderFormId', newOrderForm.orderFormId)

  return new OrderForm(newOrderForm)
}

const createNewOrderForm = async () => {
  return axios.get('/api/checkout/orderForm').then((res) => res.data)
}

const requestOrderForm = async (orderFormId: string) => {
  return axios
    .get(`/api/checkout/orderForm/${orderFormId}`)
    .then((res) => res.data)
    .catch(() => null)
}
