import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type { Product } from 'src/components/restructure/product/types/product'

import { makeProductShowcase } from './makeProductShowcase'
import type { ViewListImpressionEvent } from './types/viewListImpressionEvent'
import handleUsablePageName from './common/handleUsablePageName'

export const makeViewListImpressionEvent = (
  items: Array<{ product: Product; productItem: ProductItem }>,
  complements: {
    listName?: string
    dimension29: string
  }
): ViewListImpressionEvent => {
  const { listName, dimension29 } = complements

  const dimension24 = handleUsablePageName(window.location.pathname)

  return {
    event: 'viewListImpression',
    ecommerce: {
      currencyCode: 'BRL',
      actionField: {
        list: listName ?? '',
        dimension24,
        dimension29,
      },
      impressions: items.map((item) =>
        makeProductShowcase(
          item.product,
          item.productItem,
          dimension24,
          dimension29
        )
      ),
    },
  }
}
